import applyClasses from './css.js';
import { object } from '#polyfills';
import type { Templating } from './zod/index.js';
import type { CSSProperties } from './types.js';

const DEFAULT_FOCUS_COLOR = '#2286EF';
const DEFAULT_BORDER_COLOR = '#D3DAE1';

const WHITE = '#FFFFFF';
export const BLACK = '#2E3843';

const roomshops = {
	items: {
		// Tarjetas de las tiendas
		default: {
			backgroundColor: 'rgba(87, 101, 117, 0.30)',
		},
		focus: {
			backgroundColor: DEFAULT_FOCUS_COLOR,
			borderColor: DEFAULT_FOCUS_COLOR,
			borderWidth: '5px',
		},
	} as const satisfies Record<string, CSSProperties>,
	section1: {
		// Barra superior con el mensaje de bienvenida de la tienda
		default: {
			color: BLACK,
			backgroundColor: 'rgba(255, 255, 255, 0.20)',
		},
	} as const satisfies Record<string, CSSProperties>,
	section3: {
		// Barra central que contiene la lista de tiendas
		default: {
			backgroundColor: 'rgba(255, 255, 255, 0.60)',
		},
	} as const satisfies Record<string, CSSProperties>,
	buttons: {
		default: {
			color: BLACK,
			backgroundColor: 'rgba(255, 255, 255, 0.80)',
			borderColor: DEFAULT_BORDER_COLOR,
			borderWidth: '1px',
		},
	} as const satisfies Record<string, CSSProperties>,
} as const satisfies Templating['css']['roomshops'];

const products = {
	buttons: {
		default: {
			color: BLACK,
			backgroundColor: 'rgba(255, 255, 255, 0.80)',
			borderColor: DEFAULT_BORDER_COLOR,
			borderWidth: '1px',
		},
		active: {
			backgroundColor: '#0F63BD',
			borderColor: '#0F63BD',
			color: WHITE,
		},
		focus: {
			color: WHITE,
			backgroundColor: '#0F63BD',
			borderColor: '#0F63BD',
			borderWidth: '1px',
		},
	} as const satisfies Record<string, CSSProperties>,
	items: {
		focus: {
			borderColor: '#2286EF',
		},
	} as const satisfies Record<string, CSSProperties>,
	section1: {
		//Barra de arriba que contiene el nombre de la tienda y la lista de categorías de nivel 1
		default: {
			color: BLACK,
			backgroundColor: 'rgba(255, 255, 255, 0.20)',
		},
	} as const satisfies Record<string, CSSProperties>,
	section2: {
		//Barra con el nombre de la categoría
		default: {
			color: WHITE,
			backgroundColor: '#76889B',
		},
	} as const satisfies Record<string, CSSProperties>,
	section3: {
		//Contenedor de los objetos de cada categoría
		default: {
			color: '#2E3843',
			backgroundColor: 'rgba(245, 246, 248, 0.60)',
		},
	} as const satisfies Record<string, CSSProperties>,
} as const as Templating['css']['products'];

const productDetails = {
	buttons: {
		default: {
			color: BLACK,
			backgroundColor: WHITE,
			borderColor: '#D3DAE1',
			borderWidth: '1px',
		},
		active: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
			borderColor: '#D3DAE1', // TODO these are same as default
			borderWidth: '1px', // TODO these are same as default
		},
		focus: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
			borderColor: '#D3DAE1', // TODO these are same as default
			borderWidth: '1px', // TODO these are same as default
		},
	} as const satisfies Record<string, CSSProperties>,
	items: {
		// Tarjetas de los extras
		default: {
			backgroundColor: '#F5F6F8',
		},
		focus: {
			backgroundColor: WHITE,
			borderColor: WHITE,
			borderWidth: '5px',
		},
	} as const satisfies Record<string, CSSProperties>,
	section1: {
		// Top and bottom bar
		default: {
			color: BLACK,
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
		},
		focus: {
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
		},
	},
	section2: {
		// Tarjeta oscura con el producto principal
		default: {
			color: WHITE,
			backgroundColor: '#76889B',
		},
	},
	section3: {
		// Tarjeta que contiene a todos los extras
		default: {
			color: BLACK,
			backgroundColor: 'rgba(245, 246, 248, 0.60)',
		},
	},
} as const satisfies Templating['css']['productDetails'];

const cart = {
	buttons: {
		default: {
			color: BLACK,
			backgroundColor: WHITE,
			borderColor: '#D3DAE1',
			borderWidth: '1px',
		},
		active: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
			borderColor: '#D3DAE1', // TODO these are same as default
			borderWidth: '1px', // TODO these are same as default
		},
		focus: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
			borderColor: '#D3DAE1', // TODO these are same as default
			borderWidth: '1px', // TODO these are same as default
		},
	} as const satisfies Record<string, CSSProperties>,
	items: {
		default: {
			color: BLACK,
			backgroundColor: WHITE,
		},
		active: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
		},
		focus: {
			color: BLACK, // TODO these are same as default
			backgroundColor: WHITE, // TODO these are same as default
			borderColor: WHITE,
			borderWidth: '5px',
		},
	} as const satisfies Record<string, CSSProperties>,
	section1: {
		//Bottom bar
		default: {
			color: BLACK,
			backgroundColor: 'rgba(236, 243, 251, 0.80)',
		},
	} as const satisfies Record<string, CSSProperties>,
	section3: {
		// Cart items and total container
		default: {
			color: BLACK,
			backgroundColor: '#F5F6F8',
		},
	} as const satisfies Record<string, CSSProperties>,
} as const satisfies Templating['css']['cart'];

export const DEFAULT_STYLES = { roomshops, products, productDetails, cart } as const;

export const make_stylesheet = <T extends keyof Templating['css']>(
	template: Templating['css'][T] = {} as Templating['css'][T]
) => {
	return {
		default: applyClasses(
			object.fromEntries(
				object
					.entries(template)
					.map(([key, value]) => [
						key,
						'default' in value ? value['default'] : ({} as Record<string, CSSProperties>),
					])
			)
		),
		active: applyClasses(
			object.fromEntries(
				object
					.entries(template)
					.map(([key, value]) => [
						key,
						'active' in value ? value['active'] : ({} as Record<string, CSSProperties>),
					])
			)
		),
		focus: applyClasses(
			object.fromEntries(
				object
					.entries(template)
					.map(([key, value]) => [
						key,
						'focus' in value ? value['focus'] : ({} as Record<string, CSSProperties>),
					])
			)
		),
	} as const;
};

export const DEFAULT_STYLEHSEET = {
	roomshops: make_stylesheet(DEFAULT_STYLES.roomshops),
	products: make_stylesheet(DEFAULT_STYLES.products),
	productDetails: make_stylesheet(DEFAULT_STYLES.productDetails),
	cart: make_stylesheet(DEFAULT_STYLES.cart),
} as const;
